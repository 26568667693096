
import Vue from "vue";
import { AgendaItem } from "@/types/interfaces";

export default Vue.extend({
  name: "AgendaItem",
  props: {
    agendaItem: {
      type: Object as () => AgendaItem
    },
    isMentorAgenda: {
      type: Boolean
    },
    isWeeklyDate: {
      type: String
    }
  },
  data() {
    return {
      button: {},
      icon: {}
    };
  },
  watch: {
    "agendaItem.icon": function() {
      this.init();
    }
  },
  mounted: function(): void {
    this.init();
  },
  methods: {
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    init() {
      // Change icon, button color and text depending on status
      switch (this.agendaItem.icon) {
        case "reserved":
          if (!this.isMentorAgenda) {
            this.button = {
              text: "Re-open",
              color: "success",
              action: () => console.log("Re-open button was clicked!")
            };
          }
          this.icon = {
            name: "closed",
            color: "#FF715B"
          };
          break;
        case "available":
          if (this.isMentorAgenda) {
            this.button = {
              text: "Book session",
              color: "success",
              action: () => this.bookSession()
            };
          } else {
            this.button = {
              text: "Close Session",
              color: "warning",
              action: () => this.closeAgenda()
            };
          }
          this.icon = {
            name: "checked",
            color: "#37A000"
          };
          break;
        case "host":
          this.button = {
            text: "See details",
            color: "primary",
            action: () => this.seeDetails()
          };
          this.icon = {
            name: "agenda",
            color: "#3E85D0"
          };
          break;
        case "booked":
          if (!this.isMentorAgenda || this.agendaItem.type === "mentorcast") {
            this.button = {
              text: "See details",
              color: "primary",
              action: () => this.seeDetails()
            };
          } else {
            this.button = {};
          }
          this.icon = {
            name: "agenda",
            color: "#3E85D0"
          };
      }
    },
    seeDetails() {
      const booking_id = this.agendaItem.booking_id;
      const type = this.agendaItem.type;
      if (booking_id) {
        if (type === "mentorcast") {
          this.$router.push(`/mentorcasts/${booking_id}`);
        } else if (type === "session") {
          this.$router.push(`/sessions/${booking_id}`);
        }
      }
    },
    bookSession() {
      this.$emit("payment", this.agendaItem);
    },
    closeAgenda() {
      this.$store.dispatch("agenda/CLOSE_AGENDA", {
        id: this.agendaItem.id,
        date: this.isWeeklyDate
      });
    },
    generateIcs() {
      this.$store
        .dispatch("agenda/GENERATE_ICS", this.agendaItem.id)
        .then(data => {
          console.log(data);
          this.downloadURI(data.ics, "Agenda");
        });
    },
    downloadURI(uri: any, name: any) {
      const link = document.createElement("a");
      link.setAttribute("download", name);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
});
