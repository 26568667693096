
import Vue from "vue";
import AgendaItemContainer from "@/components/AgendaItemContainer.vue";
import moment from "moment";
import AgendaItemsLoading from "@/components/AgendaItemsLoading.vue";

export default Vue.extend({
  name: "AgendaItemWeekContainer",
  components: {
    AgendaItemContainer,
    AgendaItemsLoading
  },
  props: {
    agendas: {
      type: [Array, Object]
    },
    isMentorAgenda: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  computed: {
    filteredAgendas() {
      if (this.isMentorAgenda) {
        return Object.fromEntries(
          Object.entries(this.agendas).filter(([date]) =>
            moment(date, "YYYY-MM-DD").isSameOrAfter(
              moment().subtract(1, "days")
            )
          )
        );
      }
      return this.agendas;
    }
  },
  methods: {
    onPayment(data: any) {
      this.$emit("payment", data);
    },
    getFormattedDate(date: string) {
      return moment(date, "YYYY-MM-DD")
        .format("dddd, DD/MM/yyyy")
        .toString();
    },
    findIndex(date: string) {
      const agendas = Object.keys(this.agendas);
      return agendas.indexOf(date);
    }
  }
});
