
import Vue from "vue";
import AgendaItem from "@/components/AgendaItem.vue";

export default Vue.extend({
  name: "AgendaItemContainer",
  components: {
    AgendaItem
  },
  props: {
    agendas: {
      type: [Array, Object]
    },
    isMentorAgenda: {
      type: Boolean
    },
    isWeeklyDate: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    onPayment(data: any) {
      this.$emit("payment", data);
    }
  }
});
