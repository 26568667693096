
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import PaymentModal from "@/components/Modals/PaymentModal.vue";
import PaymentCompleteModal from "@/components/Modals/PaymentCompleteModal.vue";
import ProfileData from "@/components/Profile/ProfileData.vue";
import AgendaItemContainer from "@/components/AgendaItemContainer.vue";
import AgendaItemWeekContainer from "@/components/AgendaItemWeekContainer.vue";
import AgendaItemsLoading from "@/components/AgendaItemsLoading.vue";
import Socket from "@/services/Socket";

export default Vue.extend({
  name: "MentorAgendaPage",
  components: {
    ProfileData,
    PaymentModal,
    PaymentCompleteModal,
    AgendaItemContainer,
    AgendaItemWeekContainer,
    AgendaItemsLoading
  },
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      session: {} as any,
      session_id: "",
      viewBy: "week",
      loading: true,
      viewOptions: [
        { id: "day", name: "Day" },
        { id: "week", name: "Week" }
      ],
      // Select icons
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      }
    };
  },
  computed: {
    ...mapGetters({
      mentor: "mentor/getMentor",
      agenda: "agenda/getAgenda",
      profile: "profile/getProfile"
    })
  },
  watch: {
    date(val) {
      if (val !== "") {
        this.getAgenda();
      }
    }
  },
  mounted() {
    this.$store
      .dispatch("mentor/GET_MENTOR", {
        id: this.$route.params.id
      })
      .then(mentor => {
        if (mentor.agenda_date) {
          this.date = mentor.agenda_date;
        }
        this.getAgenda();
      });

    Socket().on(`agenda.user.${this.$route.params.id}`, data => {
      if (data.currentUserId == this.profile.id) {
        return;
      }
      this.getAgenda();
    });
  },
  methods: {
    nextOrPrevMonth(type: string) {
      const amount = type === "next" ? 1 : -1;
      this.date = moment(this.date)
        .add(amount, "day")
        .format("YYYY-MM-DD");
    },
    onPaymentComplete(id: string) {
      this.session_id = id;
      this.session.id = id;
      this.$bvModal.show("payment-complete");
    },
    getAgenda(reset = false) {
      this.loading = true;

      this.$store
        .dispatch("agenda/GET_AGENDA", {
          date: this.date,
          view: this.viewBy,
          id: this.$route.params.id
        })
        .then(() => {
          this.loading = false;
          if (reset) {
            this.$router.push(`/sessions/${this.session_id}`);
          }
        });
    },
    onPayment(data: any) {
      const session = {
        id: data.id,
        user: {
          name: this.mentor.profile.name
        },
        start_date: this.date,
        start_time: data.start_time,
        seat_price: data.seat_price
      };
      this.session = session;
      (this as any).$refs["payment_modal"].setInfoData(session).then(() => {
        this.$bvModal.show("payment");
        (this as any).$refs["payment_complete_modal"].setInfoData(session);
      });
    },
    setDate(date: string) {
      this.viewBy = "day";
      if (this.date == date) {
        this.getAgenda();
      } else {
        this.date = date;
      }
    }
  }
});
