
import Vue from "vue";

export default Vue.extend({
  name: "AgendaItemsLoading",
  props: {
    isMentorAgenda: {
      type: Boolean
    }
  }
});
